<template>
  <vs-popup
    v-if="product"
    class="catalogimportproductdetail"
    :title="$t('catalog.productPreview.productPreview')"
    :active.sync="active"
  >
    <div ref="popup">
      <vs-row>
        <vs-col class="mb-5 w-75">
          <h3>{{ $t('catalog.productPreview.requiredFields') }}: </h3>
          <p>
            <strong>{{ $t('catalog.productPreview.productName') }}:</strong>
            {{ product.title }}
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productId') }}: </strong>
            {{ mainProductId }}
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productCategory') }}: </strong>

            <span v-for="(element ,key) in product.categories" :key="key">
              {{ element }},
            </span>
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productLink') }}: </strong>
            <a :href="product.link">{{ product.link }}</a>
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productImage') }}: </strong>
            <a :href="product.image_link">{{ product.image_link }}</a>
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productAvailability') }}: </strong>
            {{ product.availability }}
          </p>
          <p>
            <strong>{{ $t('catalog.productPreview.productPrice') }}: </strong>
            {{ product.price }}
          </p>
        </vs-col>
        <vs-col class="mb-5 w-25">
          <b-img
            fluid
            :src="generateQonLink(product.image_link)"
            :alt="product.title"
            class=""
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col>
          <h3>{{ $t('catalog.productPreview.additionalFields') }}: </h3>
          <p v-for="( value , name ) in getProductAdditionalData(product)" :key="name">
            <strong>{{ name }}: </strong> {{ value }}
          </p>
          <p v-if="!(Object.keys(getProductAdditionalData(product)).length)">
            {{ $t('catalog.productPreview.missingAdditionalFields') }}
          </p>
        </vs-col>
      </vs-row>
    </div>
  </vs-popup>
</template>

<script>
import store from '@/store'
import QonStaticImage from '@/libs/QonStaticImage'
import ProductDataHelper from '@/store/catalog/productDataHelper'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'

export default {
  name: 'CatalogImportProductShowPopup',
  computed: {
    active: {
      get() {
        return moduleImportedCatalog.state.detailPopup.active
      },
      set(active) {
        moduleImportedCatalog.commit('setActiveProductPopup', active)
      },
    },
    product: {
      get() {
        return moduleImportedCatalog.state.detailPopup.product
      },
    },
    mainProductId: {
      get() {
        return moduleImportedCatalog.state.detailPopup.mainProductId
      },
    },
  },
  methods: {
    generateQonLink(url) {
      const { customerSymbol } = store.state.user.appActiveUser
      return QonStaticImage.generateQonLink(customerSymbol, url, 251, null)
    },
    getProductAdditionalData(product) {
      return ProductDataHelper.getAdditionalDataForMainProduct(product)
    },
  },
}
</script>
