





























import ProductDataHelper from '@/store/catalog/productDataHelper'
import ProductTileStore from '@/store/catalog/productTile'
import ProductId from '@/views/components/catalog/products/attributes/productId.vue'
import ProductImage from '@/views/components/catalog/products/attributes/productImage.vue'
import ProductPrice from '@/views/components/catalog/products/attributes/productPrice.vue'
import ProductAvailability from '@/views/components/catalog/products/attributes/productAvailability.vue'
import ProductCategories from '@/views/components/catalog/products/attributes/productCategories.vue'
import ToggleDetailsButtons from '@/views/components/catalog/products/toggleDetailsButtons.vue'
import ProductAttribute from '@/views/components/catalog/products/attributes/productAttribute.vue'

export default {
  name: 'ProductTile',
  components: {
    ProductAttribute,
    ToggleDetailsButtons,
    ProductCategories,
    ProductAvailability,
    ProductPrice,
    ProductImage,
    ProductId,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAdditionalDataForMainProduct(product: any) {
      return ProductDataHelper.getAdditionalDataForMainProduct(product)
    },
    showDetails() {
      return ProductTileStore.state.showDetails
    },
  },
}
