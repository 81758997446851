<template>
  <vs-row>
    <vs-col vs-w="3" vs-xs="12" class="font-weight-bolder">
      {{ $t('catalog.productList.product.categories') }}
    </vs-col>
    <vs-col vs-w="9" vs-xs="12">
      <span v-for="(category, index) in categories" :key="index">
        <b-badge pill variant="light-primary" class="mr-05 pl-1 pr-1">
          {{ category }}
        </b-badge>
      </span>
    </vs-col>
  </vs-row>
</template>

<script lang="js">
export default {
  name: 'ProductCategories',
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
}
</script>
