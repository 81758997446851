<template>
  <vs-row>
    <catalog-import-product-show-popup />
    <vs-row>
      <div id="catalogProductList">
        <div v-for="(mainProduct) in products" :key="mainProduct.id" class="product">
          <ProductTile :product="mainProduct" />
        </div>
      </div>
      <product-list-pagination />
    </vs-row>
  </vs-row>
</template>

<script>
import ProductTile from '@/views/components/catalog/products/productTile.vue'
import ProductListPagination from '@/views/components/catalog/products/productListPagination.vue'
import CatalogImportProductShowPopup from '@/views/components/catalog/CatalogImportProductShowPopup.vue'

import ProductListStore from '@/store/catalog/productsList'

export default {
  name: 'ProductList',
  components: {
    CatalogImportProductShowPopup, ProductTile, ProductListPagination,
  },
  props: {
    catalogId: {
      type: String,
      required: true,
    },
  },
  computed: {
    products() {
      return ProductListStore.state.products
    },
  },
  mounted() {
    this.getProductsData()
  },
  methods: {
    getProductsData() {
      ProductListStore.commit('setCatalogId', this.catalogId)
      ProductListStore.dispatch('getProductsList')
    },
  },
}
</script>

<style scoped>
#catalogProductList {
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 7px;
}

@media (max-width: 767px) {
  .product {
    width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .product {
    width: calc((100% - 7px) / 2);
  }
}

@media (min-width: 991px) and (max-width: 1399px) {
  .product {
    width: calc((100% - 2 * 7px) / 3);
  }
}

@media (min-width: 1399px) and (max-width: 1699px) {
  .product {
    width: calc((100% - 2 * 7px) / 3);
  }
}

@media (min-width: 1699px) and (max-width: 2099px) {
  .product {
    width: calc((100% - 3 * 7px) / 4);
  }
}

@media (min-width: 2099px) and (max-width: 2499px) {
  .product {
    width: calc((100% - 4 * 7px) / 5);
  }
}

@media (min-width: 2499px) and (max-width: 2899px) {
  .product {
    width: calc((100% - 5 * 7px) / 6);
  }
}

@media (min-width: 2899px) and (max-width: 3199px) {
  .product {
    width: calc((100% - 6 * 7px) / 7);
  }
}

@media (min-width: 3199px) {
  .product {
    width: calc((100% - 7 * 7px) / 8);
  }
}
</style>
