




















import QonStaticImage from '@/libs/QonStaticImage'
import CustomerStore from '@/store/user/customer'

export default {
  name: 'ProductImage',
  props: {
    pageLink: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    imageAlt: {
      type: String,
      required: true,
    },
  },
  methods: {
    generateQonLink(url: string) {
      const bigFontSize = 18
      const maxHeight = bigFontSize * 8
      return QonStaticImage.generateQonLink(CustomerStore.state.customerSymbol, url, null, maxHeight)
    },
    setAlternativeImage(event: any) {
      QonStaticImage.setAlternativeImage(event)
    },
  },
}
