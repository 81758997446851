




























import Vue from 'vue'
import CatalogInstanceDetailsStore from '@/store/catalog/catalogInstanceDetails'
import MappingInformation from '@/views/components/catalog/catalogInstanceDetails/mappingInformation.vue'
import ImportInformation from '@/views/components/catalog/catalogInstanceDetails/importInformation.vue'

import ProductList from '@/views/components/catalog/products/productList.vue'

export default Vue.extend({
  name: 'CatalogInstanceVue',
  components: {
    MappingInformation,
    ImportInformation,
    ProductList,
  },
  mounted() {
    CatalogInstanceDetailsStore.dispatch('getCatalogInstanceDetails', this.$route.params.catalogId)
  },

})
