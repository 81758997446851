
















import ProductTileStore from '@/store/catalog/productTile'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'

export default {
  name: 'ToggleDetailsButton',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toggleDetails() {
      return ProductTileStore.commit('toggleShowDetails')
    },
    showDetails() {
      return ProductTileStore.state.showDetails
    },
    show(product: any, mainProductId: string) {
      moduleImportedCatalog.commit(
        'showProductPopup',
        { product, mainProductId },
      )
    },
  },
}
