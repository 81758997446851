import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => ({
    showDetails: false,
  }),

  mutations: {
    toggleShowDetails: (state: any) => {
      state.showDetails = !state.showDetails
    },
  },
  actions: {
    isShowDetails(state: any) {
      return state.showDetails
    },
  },
})
