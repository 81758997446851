<template>
  <vs-row class="mt-1">
    <vs-col>
      <vs-pagination v-model="page" :total="totalPages" :max="7"/>
    </vs-col>
  </vs-row>
</template>

<script>
import ProductListStore from '@/store/catalog/productsList'

export default {
  name: 'ProductListPagination',
  computed: {
    totalPages() {
      return ProductListStore.state.totalPages
    },
    page: {
      get() {
        return ProductListStore.state.page
      },
      set(page) {
        ProductListStore.commit('setPage', page)
        ProductListStore.dispatch('getProductsList')
      },
    },
  },
}
</script>

<style scoped>
.vs-pagination--ul {
  margin-bottom: 0;
}
</style>
