interface ProductResponse {
  id: string,
  price: number,
  title: string,
  categories: string[],
  link: string,
  image_link: string,
  availability: string,
  products: any[]
}

export default class ProductDataHelper {
  static getRequiredFieldsQonIds() {
    return ['id', 'title', 'categories', 'link', 'price', 'image_link', 'availability', 'price']
  }

  static getAdditionalDataForMainProduct(productsData: ProductResponse) {
    return ProductDataHelper.getProductAdditionalData(productsData.products !== undefined ? productsData.products[0] : productsData)
  }

  static getProductAdditionalData(product: any): Array<string> {
    const additionalData = { ...product }
    const propertiesToDelete = ['id', 'title', 'price', 'categories', 'link', 'image_link', 'availability', 'products', 'suggestions', 'additional_data']
    const entities = Object.entries(additionalData)

    entities.forEach(entity => {
      const key = entity[0]
      if (key.match(/^categories_/)) {
        propertiesToDelete.push(key)
      }
    })

    propertiesToDelete.forEach(propertyName => {
      delete additionalData[propertyName]
    })

    return additionalData
  }
}
