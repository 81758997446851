<template>
  <vs-row>
    <vs-col vs-w="3" vs-xs="12" class="font-weight-bolder">
      {{ $t('catalog.productList.product.availability') }}
    </vs-col>
    <vs-col vs-w="9" vs-xs="12">
      {{ availability }}
    </vs-col>
  </vs-row>
</template>

<script lang="js">
export default {
  name: 'ProductAvailability',
  props: {
    availability: {
      type: String,
      required: true,
    },
  },
}
</script>
