<template>
  <vs-row vs-w="12">
    <b-card :title="$t('catalog.instance.mapping.header')" class="w-100">
      <b-card-text>
        <vs-row class="mb-1">
          {{ $t('catalog.instance.mapping.description') }}
        </vs-row>
        <vs-row>
          <vs-col vs-w="5" vs-type="flex" vs-justify="right" vs-align="center" class="font-weight-bold">
            {{ $t('catalog.instance.mapping.foundElement') }}
          </vs-col>
          <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
            <feather-icon icon="ArrowRightIcon" size="18" />
          </vs-col>
          <vs-col vs-w="6" vs-type="flex" vs-justify="left" vs-align="center" class="font-weight-bold">
            {{ $t('catalog.instance.mapping.changedTo') }}
          </vs-col>
        </vs-row>
        <vs-row v-for="(mapping, index) in mappings" :key="index">
          <vs-col vs-w="5" vs-type="flex" vs-justify="right" vs-align="center">
            {{ mapping.fromPath }}
          </vs-col>
          <vs-col vs-w="1" vs-type="flex" vs-justify="center" vs-align="center">
            <feather-icon v-if="mapping.toPath" icon="ArrowRightIcon" size="18" class="text-primary" />
            <feather-icon v-else icon="XIcon" size="18" class="text-danger" />
          </vs-col>
          <vs-col vs-w="6" vs-type="flex" vs-justify="left" vs-align="center">
            <div v-if="mapping.toPath">
              ({{ mapping.castType }}) {{ mapping.toPath }}
            </div>
            <div v-else class="text-warning">
              {{ $t('catalog.instance.mapping.notSet') }}
            </div>
          </vs-col>
        </vs-row>
      </b-card-text>
    </b-card>
  </vs-row>
</template>

<script>
import CatalogInstanceDetailsStore from '@/store/catalog/catalogInstanceDetails'

export default {
  name: 'MappingInformation',
  computed: {
    mappings() {
      return CatalogInstanceDetailsStore.state.mappingDetails
    },
  },
}
</script>

<style scoped>

</style>
