import Vue from 'vue'
import Vuex from 'vuex'
import CatalogApiRequest, { CatalogImportPeriods, CatalogMapping } from '@/store/catalog/catalogApiRequest'
import { AxiosResponse } from 'axios'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

const getInitialState = (): any => ({
  dataLoading: true,
  mappingDetails: null,
  importPeriods: null,
  catalogInstanceId: null,
})
export default new Vuex.Store({
  state: getInitialState(),
  mutations: {
    setCatalogInstanceDetails(state: any, data: any) {
      state.instances = data.instances
      state.dataLoading = false
    },
    storeMappingDetails(state: any, data: any) {
      state.mappingDetails = data.mappings
    },
    storeImportPeriods(state: any, data: any) {
      state.importPeriods = data.periods
    },
    setCatalogInstanceId(state: any, id: string) {
      state.catalogInstanceId = id
    },
  },
  actions: {
    getCatalogInstanceDetails(context: any, catalogId: string) {
      context.commit('setCatalogInstanceId', catalogId)

      CatalogApiRequest.getMappingsForCatalogInstance(catalogId)
        .then((result: AxiosResponse<Array<CatalogMapping>>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances', { root: true })
            return
          }

          context.commit('storeMappingDetails', { mappings: result.data, dataLoading: false })
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })

      CatalogApiRequest.getImportPeriodsForCatalogInstance(catalogId)
        .then((result: AxiosResponse<CatalogImportPeriods>) => {
          if (result.status !== 200) {
            Notifications.commit('addError', 'search.errorGettingSearchInstances', { root: true })
            return
          }

          context.commit('storeImportPeriods', { periods: result.data.periods, dataLoading: false })
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
    makeCatalogAvailableForProductionUse() {
      return CatalogApiRequest.changeCatalogTypeToProduction()
    },
  },
})
