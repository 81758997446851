const noImage = require('@/assets/images/icons/images.svg')

class QonStaticImage {
  public static generateQonLink(customerSymbol: string, imageUrl: string, width: any, height: any): string {
    let imgUrl = imageUrl.split('?')[0]

    imgUrl = encodeURI(imgUrl)

    let burl = btoa(imgUrl)
    /* zamieniamy slashe na podkreslniki, zeby nie przeszkadzaly w dzieleniu sciezki w backendzie */
    burl = burl.replace(/\//g, '_')

    const subStrOfExtension = imgUrl.substring(imgUrl.lastIndexOf('.'))
    /* w cloudfroncie obslugujemy 3 rozszerzenia, stad i tu rozpoznajemy 3 */
    const fileExtensionMatch = /(jpg|png|jpeg)/

    /* w przypadkach gdy nie ma rozszerzenia tworzymy standardowo png */
    const fileExtension = subStrOfExtension.match(fileExtensionMatch) ? subStrOfExtension.toLowerCase() : '.png'

    /* zwracamy adres */
    const imageUrlBased64AndSplit = burl.match(/.{1,32}/g)?.join('/')
    return `https://qon-csts3.quartic.com.pl/cst/${customerSymbol}/e1-c0/${width}x${height}/${imageUrlBased64AndSplit}${fileExtension}`
  }

  public static setAlternativeImage(event: any) {
    if (event.target) {
      event.target.src = noImage
    }
  }
}

export default QonStaticImage
