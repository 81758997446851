import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const userDataStoreKey = 'qonUserInfo'

export interface LoggedUserDetails {
  customerSymbol: string
  displayName?: string
  about?: string
  status?: string
  userRole?: string
  domain?: string
  features?: string[]
  modules?: string[]
  platformId?: number
}

function getCustomerSymbolFromLocalStorage(): string {
  const userFromLocalStorage = localStorage.getItem(userDataStoreKey)
  if (userFromLocalStorage !== null) {
    const userData = JSON.parse(userFromLocalStorage)
    return userData?.customerSymbol ?? 'null'
  }
  return 'null'
}

export default new Vuex.Store({
  state: (): LoggedUserDetails => ({
    customerSymbol: getCustomerSymbolFromLocalStorage(),
  }),
  getters: {
    customerSymbol(state): string {
      return state.customerSymbol
    },
  },
})
