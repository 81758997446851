<template>
  <vs-row vs-w="12">
    <b-card :title="$t('catalog.instance.import.header')" class="w-100">
      <b-card-text>
        {{ $t('catalog.instance.import.description') }}
        <vs-list>
          <vs-list-item v-for="(period, index) in periods" :key="index" vs-w="6">
            {{ period }}
          </vs-list-item>
        </vs-list>
      </b-card-text>
    </b-card>

  </vs-row>
</template>

<script>
import CatalogInstanceDetailsStore from '@/store/catalog/catalogInstanceDetails'

export default {
  name: 'ImportInformation',
  computed: {
    periods() {
      return CatalogInstanceDetailsStore.state.importPeriods
    },
  },
}
</script>
