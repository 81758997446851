import { AxiosResponse } from 'axios'
import apiRequest from '@/apiRequest'
import ApiRequestPaths from '@/apiRequestPaths'

export interface ProductListParams {
  catalogId: string
  page: number
  perPage: number
}

export default class ProductApiRequest {
  static async getProductsList(productListParams: ProductListParams): Promise<AxiosResponse<any>> {
    const productListUrl = new URL(
      ApiRequestPaths.GET_PRODUCT_LIST.replace('{catalogId}', productListParams.catalogId),
      `${process.env.VUE_APP_API_URL}/api/`,
    )

    productListUrl.searchParams.append('page', productListParams.page.toString())
    productListUrl.searchParams.append('perPage', productListParams.perPage.toString())

    return apiRequest.get<any>(productListUrl.href)
  }
}
