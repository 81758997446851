<template>
  <vs-row>
    <vs-col vs-w="3" vs-xs="12" class="font-weight-bolder">
      {{ $t('catalog.productList.product.price') }}
    </vs-col>
    <vs-col vs-w="9" vs-xs="12">
      {{ price }}
    </vs-col>
  </vs-row>
</template>

<script lang="js">
export default {
  name: 'ProductPrice',
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
}
</script>
