import Vue from 'vue'
import Vuex from 'vuex'
import ProductApiRequest from '@/store/catalog/productApiRequest'
import Notifications from '@/store/notifications/notifications'

Vue.use(Vuex)

export default new Vuex.Store({
  state: () => (
    {
      loading: false,
      catalogId: null,
      products: [],
      page: 1,
      perPage: 48,
      totalPages: 0,
    }),
  mutations: {
    setCatalogId: (state: any, catalogId: string) => {
      state.catalogId = catalogId
    },
    setPage: (state: any, page: number) => {
      state.page = page
    },
    setTotalPages: (state: any, totalPages: number) => {
      state.totalPages = totalPages
    },
    setProducts: (state:any, products: any[]) => {
      state.products = products
    },
  },
  actions: {
    getProductsList(context: any) {
      return ProductApiRequest.getProductsList({
        catalogId: context.state.catalogId,
        page: context.state.page,
        perPage: context.state.perPage,
      })
        .then(result => {
          if (result.status === 200) {
            context.commit('setProducts', result.data.products)
            context.commit('setTotalPages', result.data.totalPages)
          }
          return result
        })
        .catch(error => {
          Notifications.commit('addError', error, { root: true })
        })
    },
  },
})
