<template>
  <vs-row>
    <vs-col vs-w="3" vs-xs="12" class="font-weight-bolder">
      {{ $t('catalog.productList.product.id') }}
    </vs-col>
    <vs-col vs-w="9" vs-xs="12">
      {{ productId }}
      <b-badge v-if="variantsCount > 1" pill class="pl-1 pr-1" variant="light-primary">
        {{ $tc('catalog.productList.product.variantsCount', variantsCount) }}
      </b-badge>
      <b-badge v-else pill class="pl-1 pr-1" variant="light-secondary">
        {{ $tc('catalog.productList.product.variantsCount', 1) }}
      </b-badge>
    </vs-col>
  </vs-row>
</template>

<script>

export default {
  name: 'ProductId',
  props: {
    productId: {
      type: String,
      required: true,
    },
    variantsCount: {
      type: Number,
      required: true,
    },
  },
}
</script>
