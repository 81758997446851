














export default {
  name: 'ProductAttribute',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    capitalizeFirstLetter(text: string) {
      return text.charAt(0).toUpperCase() + text.slice(1)
    },
  },
}
